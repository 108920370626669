/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.imbc.com/broad/radio/fm/economy/index.html"
  }, "이진우의 손에 잡히는 경제 - MBC")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://podcasts.apple.com/kr/podcast/%ED%8C%9F%EC%BA%90%EC%8A%A4%ED%8A%B8-%EB%8B%B4%EB%8B%B9%EC%9E%90-%EB%B6%88%EB%A0%80%EC%8A%B5%EB%8B%88%EB%8B%A4-%EC%A3%BC%EC%9D%98-%EC%84%A4%EB%AA%85-%EA%B8%B8%EA%B3%A0-%EC%9E%A5%ED%99%A9/id437788220?i=1000574966729"
  }, "팟캐스트 담당자 불렀습니다. (주의! 설명 길고 장황) - 이진우의 손에 잡히는 경제 팟캐스트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://art19korea.com/"
  }, "ART19 Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2021/6/24/22549391/amazon-music-art19-hosting-platform-advertisers"
  }, "Amazon is acquiring a podcast hosting and monetization platform - The Verge")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
